import { apiGet } from "~/services/api";
import getRoute from "~/utilities/configs/apiMap";
import { Notification } from "~/services/notifications/toast";
import { updateUnitPageSeo } from "~/services/SEO/updateUnitPageSeo";
import {
  getLocalStorage,
  writePersistentLocalStorage,
} from "~/services/LocalStorage/localStorage";
import {
  Api,
  type EstateFinanceDTO,
  type EstateTimelineDTO,
  type LandFinanceDTO,
  type UnitResponseDTO,
} from "~/services/swagger/Api";

const nuxtApp = useNuxtApp();
const apiClient = new Api();

export interface UpdatedUnitResponseDTO extends Omit<UnitResponseDTO, "finance"> {
  finance: LandFinanceDTO & EstateFinanceDTO;
  timeline: EstateTimelineDTO[];
}

export const unitPageStore = defineStore("unitPage", {
  state: () => ({
    unitData: null as UpdatedUnitResponseDTO | null,
    currentElementInstance: "" as string,
  }),
  actions: {
    async getAllTokens() {
      try {
        const data = await apiGet({
          url: getRoute({ endpont: "get_blockchains" }),
        });
        if (!data) return;
      } catch (error) {
        const errorMessage =
          (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
        createErrorNotify({ message: errorMessage });
      }
    },
    createUnit() {
      const state = this;

      class Unit {
        async getPageData(id: string, not404?: boolean) {
          if (!id) {
            if (!not404) {
              navigateTo("/404");
            }
            return null;
          }
          try {
            const response = await apiClient.api.unitsControllerRetrieve(id);
            if (!response.data) {
              if (!not404) {
                navigateTo("/404");
              }
              return null;
            }
            state.unitData = response.data as UpdatedUnitResponseDTO;
            updateUnitPageSeo(
              state.unitData,
              state.unitData.sanitizeName as string
            );
            state.getAllTokens();
            nextTick(() => {
              setVisitMetrics(state.unitData);
            });
            return state.unitData;
          } catch (error) {
            if (!not404) {
              navigateTo("/404");
            }
            return null;
          }
        }
        async addToFavorite(id: string) {
          try {
            await apiClient.api.unitsControllerFavorite(id);
            return true;
          } catch (error) {
            createErrorNotify({
              message: nuxtApp.$i18n.t("errors.catch_all"),
            });
            return false;
          }
        }
      }
      return new Unit();
    },
    
    async proccessUnitPageData(data: UpdatedUnitResponseDTO) {
      this.unitData = data;
      updateUnitPageSeo(this.unitData, this.unitData.sanitizeName as string);
      this.getAllTokens();
      nextTick(() => {
        setVisitMetrics(this.unitData);
      });
    },
  },
});

const createErrorNotify = (args: { message: string; delay?: number }) => {
  const errorNotify = new Notification({
    type: "error",
    message: args.message,
    timeout: args.delay ?? 5000,
  });
  errorNotify.createNewToast();
};

const setVisitMetrics = async (unit: UpdatedUnitResponseDTO| null) => {
  const apiClient = new Api();
  const today = new Date();
  const pageId = unit?.id || "";

  let metrics: {
    viewsPerDay: number;
    viewsForAllTime: number;
  } = unit?.metrics || {
    viewsPerDay: 0,
    viewsForAllTime: 0,
  };

  // Получаем информацию о посещениях из локального хранилища
  const visitData = getLocalStorage("unit_page_visit");

  if (!visitData) {
    // Если пользователь зашел на страницу первый раз за сегодня или не было никаких записей
    writePersistentLocalStorage("unit_page_visit", {
      date: today,
      pageIds: [pageId],
    });
    metrics = {
      viewsPerDay: metrics.viewsPerDay + 1,
      viewsForAllTime: metrics.viewsForAllTime + 1,
    };
    // Отправляем обновленные метрики на бэкэнд
    apiClient.api.unitsControllerUpdateMetrics(pageId);
  } else {
    const visitDate = new Date(visitData.date);

    // Проверяем, является ли текущая дата новой
    if (visitDate.toDateString() !== today.toDateString()) {
      // Обновляем дату и записываем текущую страницу как посещенную
      writePersistentLocalStorage("unit_page_visit", {
        date: today,
        pageIds: [pageId],
      });
      metrics = {
        viewsPerDay: metrics.viewsPerDay + 1,
        viewsForAllTime: metrics.viewsForAllTime + 1,
      };
      // Отправляем обновленные метрики на бэкэнд
      apiClient.api.unitsControllerUpdateMetrics(pageId);
    } else if (!visitData.pageIds.includes(pageId)) {
      // Если текущая дата совпадает, но страница еще не была посещена
      visitData.pageIds.push(pageId);
      writePersistentLocalStorage("unit_page_visit", visitData);
      metrics = {
        viewsPerDay: metrics.viewsPerDay + 1,
        viewsForAllTime: metrics.viewsForAllTime + 1,
      };
      // Отправляем обновленные метрики на бэкэнд
      apiClient.api.unitsControllerUpdateMetrics(pageId);
    }
  }
};
