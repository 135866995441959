import { formatPrice } from "~/utilities/helpers/format-data/number";
import type { UnitResponseDTO } from "../swagger/Api";
import { convertServerStars } from "~/utilities/helpers/review/convertServerRating";

export const updateUnitPageSeo = (
  unitPageData: UnitResponseDTO,
  pageId: string
) => {
  const getUnitPhotoLink =
    unitPageData?.files.find((photo) => photo.type === "image")?.file.url ||
    "https://www.rwa-estate.com/hero.png";
  const descriptions =
    "Browse RWA Estate's tokenized real estate listings. Discover property prices, investment returns, token availability, and more. Explore trending properties, value growth, and investment opportunities with real-time data.";
  const descriptionUnit = `Explore ${unitPageData?.name} on RWA Estate. View property details, investment calculator, and token availability for purchase`;
  const title = `Invest in ${unitPageData?.name}${unitPageData?.minimumInvestmentUSD?' from '+ formatPrice(unitPageData?.minimumInvestmentUSD, true):''}$ - Tokenized Real Estate`;

  useSeoMeta({
    ogTitle: title,
    ogDescription: descriptionUnit,
    keywords: Array.from(
      new Set([
        ...(unitPageData?.name?.split(" ") || []),
        "Tokenized Real Estate Investment",
        "Property Investment",
        "Tokenized Property",
        "Real Estate Tokens for Purchase",
        `Investment Property in ${unitPageData?.location || "Unknown Location"}`,
      ])
    ).join(", "),
    ogImage: getUnitPhotoLink,
    ogUrl: "https://www.rwa-estate.com/property/" + pageId,
    twitterCard: "summary_large_image",
    twitterTitle: title,
    twitterDescription: descriptionUnit,
    twitterImage: getUnitPhotoLink,
  });

  useHead({
    title: title,
    meta: [
      {
        name: "robots",
        content: "index, follow",
      },
    ],
    link: [
      {
        rel: "canonical",
        href: `https://www.rwa-estate.com/property/${unitPageData?.sanitizeName}`,
      },
    ],
    script: [
      {
        type: "application/ld+json",
        children: [
          {
            "@context": "http://schema.org",
            "@type": "Organization",
            name: "RWA Estate",
            address: "Not provided",
            description: descriptions,
            url: "https://www.rwa-estate.com/",
            logo: "https://www.rwa-estate.com/favicon.ico",
            image: "https://www.rwa-estate.com/hero.png",
            telephone: "0",
          },
          {
            "@context": "http://schema.org",
            "@type": "RealEstateAgent",
            name: "RWA Estate",
            logo: "https://www.rwa-estate.com/favicon.ico",
            image: "https://www.rwa-estate.com/hero.png",
            url: "https://www.rwa-estate.com/",
            telephone: "0",
            email: "hi@rwa-estate.com",
            priceRange: "$$$",
            address: "Not provided",
          },

          {
            "@context": "http://schema.org",
            "@type": "LocalBusiness",
            name: title,
            image: getUnitPhotoLink,
            address: {
              "@type": "PostalAddress",
              addressCountry: {
                "@type": "Country",
                name: unitPageData?.country?.name || "Not provided",
              },
              addressRegion: unitPageData?.country?.name || "Not provided",
              addressLocality: unitPageData?.country?.code || "Not provided",
              streetAddress: unitPageData?.location || "Not provided",
            },
            description:
              descriptionUnit ??
              "Tokenized real estate property for investment.",
            telephone: "0",
            geo: {
              "@type": "GeoCoordinates",
              "@context": "http://schema.org",
              latitude: unitPageData?.coords?.[0] ?? 0,
              longitude: unitPageData?.coords?.[1] ?? 0,
            },
            aggregateRating: {
              "@type": "AggregateRating",
              worstRating: 1,
              bestRating: 5,
              reviewCount: unitPageData?.reviews?.length ?? 0,
              ratingValue: unitPageData?.rating ?? 0,
            },
            review:
              unitPageData?.reviews?.slice(0, 5).map((review: any) => ({
                "@type": "Review",
                author: {
                  "@type": "Person",
                  name: review.user.firstName || "Anonymous",
                },
                datePublished: review.createdAt || new Date().toISOString(),
                reviewBody: review.text || "No review text available",

                reviewRating: {
                  "@type": "Rating",
                  ratingValue: convertServerStars(review.rating),
                },
              })) || [],
          },
        ],
      },
    ],
  });
};
